import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import ServiceHero from "../../components/service-hero";
import Badges from "../../components/badges";
import OurServicesSection from "../../components/our-services-section";
import Reviews from "../../components/reviews";
import MotForm from "../../components/mot-form";
import { checkPropertiesForNull } from "../../../utils";
import { SafeHtmlParser } from "../../components/safeHtmlParser";

const EVRPage = () => {
	const data = useStaticQuery(graphql`
		query {
			reviews {
				reviewCount
				reviewAuthor
				reviewDate
				reviewContent
			}
			schemaLogo: file(relativePath: { eq: "icon.png" }) {
				publicURL
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "electric-vehicle-repairs" }) {
				slug
				electricVehicleRepairsFields {
					electricVehicleRepairsBanner {
						electricVehicleRepairsBannerHeading
						electricVehicleRepairsBannerBackgroundImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					electricVehicleRepairsContentSection {
						electricVehicleRepairsContentHeading
						electricVehicleRepairsContentText
					}
					electricVehicleRepairsForm {
						electricVehicleRepairsFormHeading
					}
					electricVehicleRepairsLogoSlider {
						electricVehicleRepairsLogoSliderHeading
						electricVehicleRepairsLogos {
							nodes {
								... on WpVehicleLogo {
									vehicleLogoFields {
										logoUrl
										logoImage {
											node {
												altText
												localFile {
													publicURL
													childImageSharp {
														gatsbyImageData(
															formats: [AUTO, WEBP]
															quality: 100
															transformOptions: {
																cropFocus: CENTER
																fit: CONTAIN
															}
															layout: CONSTRAINED
															placeholder: BLURRED
														)
													}
												}
											}
										}
									}
								}
							}
						}
						electricVehicleRepairsLogoSliderNotice
					}
					electricVehicleRepairsReviews {
						electricVehicleRepairsReviewsHeading
					}
					electricVehicleRepairsServicesCards {
						electricVehicleRepairsServicesCardsHeading
						electricVehicleRepairsServicesCards {
							nodes {
								... on WpService {
									title
									servicesPostFields {
										serviceUrl {
											target
											title
											url
										}
									}
									featuredImage {
										node {
											altText
											localFile {
												publicURL
												childImageSharp {
													gatsbyImageData(
														formats: [AUTO, WEBP]
														quality: 100
														transformOptions: {
															cropFocus: CENTER
															fit: CONTAIN
														}
														layout: CONSTRAINED
														placeholder: BLURRED
													)
												}
											}
										}
									}
								}
							}
						}
					}
					electricVehicleRepairsVideo {
						electricVehicleRepairsUploadVideo {
							node {
								altText
								mediaItemUrl
							}
						}
					}
				}
				seoFields {
					title
					opengraphTitle
					opengraphDescription
					metaDescription
					localBusinessSchema
					image {
						node {
							altText
							localFile {
								childImageSharp {
									original {
										height
										width
									}
								}
								publicURL
							}
						}
					}
				}
			}
		}
	`);

	const {
		pageData: { seoFields, electricVehicleRepairsFields, slug },
	} = data;

	const {
		electricVehicleRepairsBanner,
		electricVehicleRepairsContentSection,
		electricVehicleRepairsForm,
		electricVehicleRepairsLogoSlider,
		electricVehicleRepairsReviews,
		electricVehicleRepairsServicesCards,
		electricVehicleRepairsVideo,
	} = electricVehicleRepairsFields;

	const siteUrl = data.site.siteMetadata.siteUrl;

	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id": `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
		image: `https://www.ecfcarcare.co.uk${seoFields?.image?.node?.localFile.publicURL}`,
		mpn: `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
		sku: `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
		description: `${seoFields?.metaDescription}`,
		logo: `https://www.ecfcarcare.co.uk${data.schemaLogo.publicURL}`,
		name: "ECF Car Care",
		url: "https://www.ecfcarcare.co.uk",
		brand: {
			"@type": "Organization",
			logo: `https://www.ecfcarcare.co.uk${data.schemaLogo.publicURL}`,
			image: `https://www.ecfcarcare.co.uk${seoFields?.image?.node?.localFile.publicURL}`,
			name: "ECF Car Care",
			url: "https://www.ecfcarcare.co.uk",
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: `${data.reviews.reviewCount}`,
			ratingValue: "5",
			bestRating: "5",
			worstRating: "1",
		},
		review: {
			"@type": "Review",
			url: "https://www.ecfcarcare.co.uk/reviews",
			datePublished: `${data.reviews.reviewDate}`,
			reviewBody: `${data.reviews.reviewContent}`,
			author: {
				"@type": "Person",
				name: `${data.reviews.reviewAuthor}`,
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				bestRating: "5",
				worstRating: "1",
			},
			publisher: {
				"@type": "Organization",
				name: "ECF Car Care",
				sameAs: "https://www.ecfcarcare.co.uk",
			},
		},
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "What We Do",
				item: {
					url: `${siteUrl}/what-we-do`,
					id: `${siteUrl}/what-we-do`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Electric Vehicle Repairs",
				item: {
					url: `${siteUrl}/what-we-do/electric-vehicle-repairs`,
					id: `${siteUrl}/what-we-do/electric-vehicle-repairs`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(serviceSchema)}
				</script>
			</Helmet>

			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.localFile.publicURL}`,
							width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Layout>
				{electricVehicleRepairsBanner && (
					<section>
						<ServiceHero
							title={
								electricVehicleRepairsBanner.electricVehicleRepairsBannerHeading
							}
							backgroundImage={
								electricVehicleRepairsBanner
									.electricVehicleRepairsBannerBackgroundImage.node?.localFile
									.childImageSharp.gatsbyImageData
							}
							imgAlt={
								electricVehicleRepairsBanner
									.electricVehicleRepairsBannerBackgroundImage.node?.altText
							}
						/>
					</section>
				)}
				{electricVehicleRepairsContentSection &&
					!checkPropertiesForNull(electricVehicleRepairsContentSection, [
						"electricVehicleRepairsContentHeading",
					]) && (
						<section className="pt-5  pt-lg-8 position-relative">
							<Container>
								<Row>
									<Col className="position-relative">
										<h2 className="text-center pb-5">
											{
												electricVehicleRepairsContentSection.electricVehicleRepairsContentHeading
											}
										</h2>
									</Col>
								</Row>
								<Row>
									<Col className="text-start">
										<div />
										<SafeHtmlParser
											htmlContent={
												electricVehicleRepairsContentSection?.electricVehicleRepairsContentText
											}
										/>
									</Col>
								</Row>
							</Container>
						</section>
					)}

				{electricVehicleRepairsLogoSlider &&
					!checkPropertiesForNull(electricVehicleRepairsLogoSlider, [
						"electricVehicleRepairsLogoSliderHeading",
					]) && (
						<Badges
							title={
								electricVehicleRepairsLogoSlider.electricVehicleRepairsLogoSliderHeading
							}
							text={
								electricVehicleRepairsLogoSlider.electricVehicleRepairsLogoSliderNotice
							}
							logos={
								electricVehicleRepairsLogoSlider.electricVehicleRepairsLogos
									?.nodes
							}
						/>
					)}
				{electricVehicleRepairsVideo &&
					!checkPropertiesForNull(electricVehicleRepairsVideo, [
						"electricVehicleRepairsUploadVideo",
					]) && (
						<section className="bg-white">
							<Container className="pb-5 pb-lg-7">
								<Row>
									<Col>
										<div>
											<video style={{ width: "100%" }} controls>
												<source
													src={
														electricVehicleRepairsVideo
															.electricVehicleRepairsUploadVideo?.node
															.mediaItemUrl
													}
													type="video/mp4"
												/>
												Sorry, your browser doesn't support videos.
											</video>
										</div>
									</Col>
								</Row>
							</Container>
						</section>
					)}

				<section>
					<Reviews
						title={
							electricVehicleRepairsReviews?.electricVehicleRepairsReviewsHeading
						}
					/>
				</section>
				<section id="book" className="pb-5 pt-5 py-lg-7">
					<Container>
						<Row className="justify-content-center">
							<Col lg={6}>
								<h2 className="text-center pb-4">
									{electricVehicleRepairsForm.electricVehicleRepairsFormHeading ||
										"BOOK YOUR APPOINTMENT"}
								</h2>
								<div
									style={{ background: "#F9F9F9", borderRadius: "18px" }}
									className="p-md-5 p-4"
								>
									<MotForm page="Electric Vehicle Repairs" />
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				{/* {electricVehicleRepairsServicesCards &&
          !checkPropertiesForNull(electricVehicleRepairsServicesCards, [
            "electricVehicleRepairsServicesCardsHeading",
          ]) && (
            <OurServicesSection
              title={
                electricVehicleRepairsServicesCards.electricVehicleRepairsServicesCardsHeading
              }
              services={
                electricVehicleRepairsServicesCards
                  .electricVehicleRepairsServicesCards?.nodes
              }
            />
          )} */}
			</Layout>
		</>
	);
};

export default EVRPage;
